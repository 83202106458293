import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_Node-RED_Tutorials/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "HTML5 video stream for the Node-RED Dashboard",
  "path": "/Frequently_Asked_Question/HTML5_Video_mit_Node-RED_Dashboard/",
  "dateChanged": "2020-12-09",
  "author": "Mike Polinowski",
  "excerpt": "I found your instructions for integrating the video stream into the Node-RED Dashboard. The new Full HD cameras now support a HTML5 video stream. Is it possible to use it directly in Node-RED?",
  "image": "../../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='FAQs - HTML5 video stream for the Node-RED Dashboard' dateChanged='2020-12-09' author='Mike Polinowski' tag='INSTAR IP Camera' description='I found your instructions for integrating the video stream into the Node-RED Dashboard. The new Full HD cameras now support a HTML5 video stream. Is it possible to use it directly in Node-RED?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/HTML5_Video_mit_Node-RED_Dashboard/' locationFR='/fr/Frequently_Asked_Question/HTML5_Video_mit_Node-RED_Dashboard/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "html5-video-stream-for-the-node-red-dashboard",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#html5-video-stream-for-the-node-red-dashboard",
        "aria-label": "html5 video stream for the node red dashboard permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HTML5 video stream for the Node-RED Dashboard`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I found your instructions for `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Node-RED_Dashboard_Live_Video/"
      }}>{`integrating the video stream into the Node-RED Dashboard`}</a>{`. The new Full HD cameras now support a HTML5 video stream. Is it possible to use it directly in Node-RED?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: You can `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Website_Integration/HTML5_Stream_Full_HD_Cameras/"
      }}>{`download a collection of files here`}</a>{` that you can use to embed the HTML5 video stream from your camera into a web page. You can also use these files together with the Node-RED Dashboard.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#preparation"
        }}>{`Preparation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#creating-the-hmtl-template-nodes"
        }}>{`Creating the HMTL Template Nodes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#node-red-dashboard"
        }}>{`Node-RED Dashboard`}</a></li>
    </ul>
    <h2 {...{
      "id": "preparation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#preparation",
        "aria-label": "preparation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Preparation`}</h2>
    <p>{`To do this, activate the `}<a parentName="p" {...{
        "href": "https://nodered.org/docs/user-guide/runtime/configuration"
      }}>{`directory for static files (httpStatic)`}</a>{` in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`settings.js`}</code>{` file of Node-RED - in my case a directory was already set there `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/data/api`}</code>{`. You can choose any directory you like. Just make sure that the directory exists and Node-RED has access to this directory:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2f2b55c3bb2d4750a58e6cbb831be5a5/dc333/HTML5_Node-RED_Dashboard_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABJ0lEQVQY022PW2+CQBSE1z1LbVe5CcodcZFqBKKAKDSttWAf+v//ULPQ2EabfA8zOTMnGQRaAnYpmHsy25FJTLS0IyZ6wu0kIVpClBURw3uQqiaGWeN5Bc4eZjuwS3AOYB/APYJZgFWCmYMegxSCyG5Aj+PQktaSlRIvI+ozjBcg9bdOXBH/ASGJGUq8ovETqyF6ATcDPwd/D17OhZeBX4CfEyXif2/KD/JyKIeCyIRRAKOAJ670obvObxm0GJyaj3SOxK0Et+oG1+BWYGQ/m+Wo+9vxtzyYbdGyGbAzYu+INWhxxmGD1184+sTzV05wwlaBtQ2epljfYHmJxaAHKdNq4raKfVKMt9CutnY59Brqt9RrqX/hcHuh3kdnW6qXVEqonFI5/QYbRCv0KA/6NQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2f2b55c3bb2d4750a58e6cbb831be5a5/e4706/HTML5_Node-RED_Dashboard_01.avif 230w", "/en/static/2f2b55c3bb2d4750a58e6cbb831be5a5/d1af7/HTML5_Node-RED_Dashboard_01.avif 460w", "/en/static/2f2b55c3bb2d4750a58e6cbb831be5a5/7f308/HTML5_Node-RED_Dashboard_01.avif 920w", "/en/static/2f2b55c3bb2d4750a58e6cbb831be5a5/da11b/HTML5_Node-RED_Dashboard_01.avif 938w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2f2b55c3bb2d4750a58e6cbb831be5a5/a0b58/HTML5_Node-RED_Dashboard_01.webp 230w", "/en/static/2f2b55c3bb2d4750a58e6cbb831be5a5/bc10c/HTML5_Node-RED_Dashboard_01.webp 460w", "/en/static/2f2b55c3bb2d4750a58e6cbb831be5a5/966d8/HTML5_Node-RED_Dashboard_01.webp 920w", "/en/static/2f2b55c3bb2d4750a58e6cbb831be5a5/e5056/HTML5_Node-RED_Dashboard_01.webp 938w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2f2b55c3bb2d4750a58e6cbb831be5a5/81c8e/HTML5_Node-RED_Dashboard_01.png 230w", "/en/static/2f2b55c3bb2d4750a58e6cbb831be5a5/08a84/HTML5_Node-RED_Dashboard_01.png 460w", "/en/static/2f2b55c3bb2d4750a58e6cbb831be5a5/c0255/HTML5_Node-RED_Dashboard_01.png 920w", "/en/static/2f2b55c3bb2d4750a58e6cbb831be5a5/dc333/HTML5_Node-RED_Dashboard_01.png 938w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2f2b55c3bb2d4750a58e6cbb831be5a5/c0255/HTML5_Node-RED_Dashboard_01.png",
              "alt": "HTML5 Videostream für das Node-RED Dashboard",
              "title": "HTML5 Videostream für das Node-RED Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In this folder, create a new `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/ui' subfolder, and copy the `}</code>{`js' folder from the `}<a parentName="p" {...{
        "href": "http://wiki.instar.com/dl/Developer/html5instarfullhd.zip"
      }}>{`provided Zip Container`}</a>{` to this folder:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`api
└── ui
   └── js
      ├── commonff.js
      ├── decworker.js
      ├── g711.js
      ├── hi_h264dec.js
      ├── libffmpeg.js
      ├── libffmpeg.wasm
      ├── NetThread.js
      ├── pcm-player.js
      ├── video-player.js
      └── webgl-player.js`}</code></pre></div>
    <p>{`Now Node-RED has all Javascript files, which are needed for the integration of the HTML5 video stream.`}</p>
    <h2 {...{
      "id": "creating-the-hmtl-template-nodes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#creating-the-hmtl-template-nodes",
        "aria-label": "creating the hmtl template nodes permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Creating the HMTL Template Nodes`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "833px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3f5d1ec40100126610b6957d0e166e8a/5205c/HTML5_Node-RED_Dashboard_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABB0lEQVQoz43O0UqDUADG8fOeBd0FEUF30Qt0v4KQXRZFdDMK2sZcbEabxGTmPGSZaZ5tup3NHV0q0lFPsAdIfxff3R8+8IUXbd1ovX8uCEFL71rVah9GmCR5ltG0ANDmuGlYdd30w0geT7buGge84IURYyxN0+xfoGOh04HMyercD6Dj7DUej4U+iWLG8jKxXREHnDya+T503N0H/qjzTOKyMaq8DLnhaEZ8OHU2cW8VlbstmN9nolSV1WWwVsaTnfvmYVvwSsYtwzx56p9Lr876J6bU9FY2CX4pzbNi4EKB27X6Pt+F8C21bcYYy/PNFAPS1L1U4JWmWz0R39wi10UIYYyTEv4AfH3waXHTRGYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3f5d1ec40100126610b6957d0e166e8a/e4706/HTML5_Node-RED_Dashboard_02.avif 230w", "/en/static/3f5d1ec40100126610b6957d0e166e8a/d1af7/HTML5_Node-RED_Dashboard_02.avif 460w", "/en/static/3f5d1ec40100126610b6957d0e166e8a/ffdf5/HTML5_Node-RED_Dashboard_02.avif 833w"],
              "sizes": "(max-width: 833px) 100vw, 833px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3f5d1ec40100126610b6957d0e166e8a/a0b58/HTML5_Node-RED_Dashboard_02.webp 230w", "/en/static/3f5d1ec40100126610b6957d0e166e8a/bc10c/HTML5_Node-RED_Dashboard_02.webp 460w", "/en/static/3f5d1ec40100126610b6957d0e166e8a/184c4/HTML5_Node-RED_Dashboard_02.webp 833w"],
              "sizes": "(max-width: 833px) 100vw, 833px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3f5d1ec40100126610b6957d0e166e8a/81c8e/HTML5_Node-RED_Dashboard_02.png 230w", "/en/static/3f5d1ec40100126610b6957d0e166e8a/08a84/HTML5_Node-RED_Dashboard_02.png 460w", "/en/static/3f5d1ec40100126610b6957d0e166e8a/5205c/HTML5_Node-RED_Dashboard_02.png 833w"],
              "sizes": "(max-width: 833px) 100vw, 833px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3f5d1ec40100126610b6957d0e166e8a/5205c/HTML5_Node-RED_Dashboard_02.png",
              "alt": "HTML5 Videostream für das Node-RED Dashboard",
              "title": "HTML5 Videostream für das Node-RED Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Next you have to take one HTML Template Node (belongs to the Node-RED Dashboard Nodes) for each camera you want to include and fill it as follows:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/81dbcbd2ec390a70dff6b6e46dd1c5c8/35a31/HTML5_Node-RED_Dashboard_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACDklEQVQoz32S208TQRTG938XKg9UfYEXE198U6rEQBHKXgpbSgX1D/CBxCbd7d7YncuZmTOXNdtKNRH5PUwmmXzfOd+Z420/29rpPd/a2u71ejsr+v3+iyfp7+6+3Nt7tb/vfbuZfZ1G32+vkyQlTVPXtZRSPwGia9vkLJh/PPLiYOh/eB2evF8skrquGWNaa/V/UCml9c8vt/PrG2/iH4WHb8afB0VRWms3BYyxj+KcM8ZY56xz3vTSvzw9iP1hmi4BQClFKCGEmMfUa1/nXOucNca7mk7jeDKbzZLFAkBYY4EDanT/0Latc85a266w1nrB5OowHJ9eXCzTFBGNtdqY7rFz786Vpl1fN0ZdeETPjyeD82AYjbMso4SIpkYAEAQEUYILQbmopeSaMaNw078xBgC80WV8EITDaJznOaeUz+csS/LqLqvueF2U9/O0+NHcL0SaGsbbB7TWVVV5o3gyCMJhGGVZJgBk0whGCC0orYA2jFaCNwjMgljF+CMuisIbxV3l467tJaNUNg2iVEqgkkoKJYWR0hg0Sv09OUTsxF3mkX8SRkmSlGXBGlqVpRASoGlIyTnj0CGlxAe01gCQ53mX+Z0fHEfRcply4FJgVZUKUUDNgTLGGGcAsLYghGiNbdsaY+q69oYX8dvR+acwSpOEc66xG6NCNBq17nZRSLEuK5X8vSGrPxNC/AIS0PbJCNTAkgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/81dbcbd2ec390a70dff6b6e46dd1c5c8/e4706/HTML5_Node-RED_Dashboard_03.avif 230w", "/en/static/81dbcbd2ec390a70dff6b6e46dd1c5c8/d1af7/HTML5_Node-RED_Dashboard_03.avif 460w", "/en/static/81dbcbd2ec390a70dff6b6e46dd1c5c8/7f308/HTML5_Node-RED_Dashboard_03.avif 920w", "/en/static/81dbcbd2ec390a70dff6b6e46dd1c5c8/25925/HTML5_Node-RED_Dashboard_03.avif 1028w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/81dbcbd2ec390a70dff6b6e46dd1c5c8/a0b58/HTML5_Node-RED_Dashboard_03.webp 230w", "/en/static/81dbcbd2ec390a70dff6b6e46dd1c5c8/bc10c/HTML5_Node-RED_Dashboard_03.webp 460w", "/en/static/81dbcbd2ec390a70dff6b6e46dd1c5c8/966d8/HTML5_Node-RED_Dashboard_03.webp 920w", "/en/static/81dbcbd2ec390a70dff6b6e46dd1c5c8/5ea8e/HTML5_Node-RED_Dashboard_03.webp 1028w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/81dbcbd2ec390a70dff6b6e46dd1c5c8/81c8e/HTML5_Node-RED_Dashboard_03.png 230w", "/en/static/81dbcbd2ec390a70dff6b6e46dd1c5c8/08a84/HTML5_Node-RED_Dashboard_03.png 460w", "/en/static/81dbcbd2ec390a70dff6b6e46dd1c5c8/c0255/HTML5_Node-RED_Dashboard_03.png 920w", "/en/static/81dbcbd2ec390a70dff6b6e46dd1c5c8/35a31/HTML5_Node-RED_Dashboard_03.png 1028w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/81dbcbd2ec390a70dff6b6e46dd1c5c8/c0255/HTML5_Node-RED_Dashboard_03.png",
              "alt": "HTML5 Videostream für das Node-RED Dashboard",
              "title": "HTML5 Videostream für das Node-RED Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Please note that each camera requires a `}<strong parentName="p">{`separate instance of the player`}</strong>{`. The following is the example of my `}<strong parentName="p">{`5th`}</strong>{` camera, which I added in Node-RED. Accordingly, the canvas has the ID `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`activeVideo5`}</code>{` and the player has the name `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`player5`}</code>{`. For the next camera I could copy this template node, but had to change these names to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`activeVideo6`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`player6`}</code>{` in `}<strong parentName="p">{`ALL places`}</strong>{`!`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`script`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`text/javascript`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`js/commonff.js`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token script"
          }}></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`script`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`text/javascript`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`js/video-player.js`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token script"
          }}></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`script`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`text/javascript`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`js/webgl-player.js`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token script"
          }}></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`script`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`text/javascript`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`js/pcm-player.js`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token script"
          }}></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`script`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`text/javascript`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`js/g711.js`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token script"
          }}></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- this is the video the video element -->`}</span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`canvas`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`activeVideo5`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`width`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`1280`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`height`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`720`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`canvas`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token script"
          }}><span parentName="span" {...{
              "className": "token language-javascript"
            }}>{`
    
    `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`var`}</span>{` cam_host     `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'192.168.2.20'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{` `}<span parentName="span" {...{
                "className": "token comment"
              }}>{`// DDNS or local ip`}</span>{`
    `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`var`}</span>{` cam_port     `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`80`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{` `}<span parentName="span" {...{
                "className": "token comment"
              }}>{`// http port`}</span>{`

    `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`var`}</span>{` cam_user     `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'admin'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{` `}<span parentName="span" {...{
                "className": "token comment"
              }}>{`// credentials for guest account recommended`}</span>{`
    `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`var`}</span>{` cam_pass     `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'instar'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{` `}<span parentName="span" {...{
                "className": "token comment"
              }}>{`// credentials for guest account recommended`}</span>{`

    `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`var`}</span>{` cam_stream   `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`13`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{` `}<span parentName="span" {...{
                "className": "token comment"
              }}>{`// 11 = FullHD; 12 medium res, 13 small res`}</span>{`

    `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`var`}</span>{` canvas `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` document`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`getElementById`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`"activeVideo5"`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
    
    `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`var`}</span>{` player5 `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`null`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
    
    `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`function`}</span>{` `}<span parentName="span" {...{
                "className": "token function"
              }}>{`startvideo`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token parameter"
              }}>{`cam_host`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` cam_port`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` cam_stream`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` cam_user`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` cam_pass`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
        `}<span parentName="span" {...{
                "className": "token comment"
              }}>{`// init video player5`}</span>{`
        player5 `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`new`}</span>{` `}<span parentName="span" {...{
                "className": "token class-name"
              }}>{`HxPlayer`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
        self`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`player5`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`init`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`canvas`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{`canvas`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`width`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span><span parentName="span" {...{
                "className": "token number"
              }}>{`640`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`height`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span><span parentName="span" {...{
                "className": "token number"
              }}>{`352`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`

        `}<span parentName="span" {...{
                "className": "token comment"
              }}>{`// play video`}</span>{`
        player5`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`playvideo`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`cam_host`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` cam_port`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` cam_stream`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` cam_user`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` cam_pass`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
    `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span>{`

    `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`function`}</span>{` `}<span parentName="span" {...{
                "className": "token function"
              }}>{`stopvideo`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
        `}<span parentName="span" {...{
                "className": "token comment"
              }}>{`// stop video`}</span>{`
        player5`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`stopvideo`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
        player5 `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`null`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
    `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span>{`
    
    
    `}<span parentName="span" {...{
                "className": "token comment"
              }}>{`// initialize and play video`}</span>{`
    
    `}<span parentName="span" {...{
                "className": "token function"
              }}>{`startvideo`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`cam_host`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` cam_port`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` cam_stream`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` cam_user`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` cam_pass`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{`
    
    

`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <h2 {...{
      "id": "node-red-dashboard",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#node-red-dashboard",
        "aria-label": "node red dashboard permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED Dashboard`}</h2>
    <p>{`You can now see the h.264 video of all integrated cameras in your Node-RED Dashboard:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7d79a383305f76787d327e678aef87f7/5a190/HTML5_Node-RED_Dashboard_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.52173913043479%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACz0lEQVQozyXL227aBgCAYd8tySZNWlmSzlDAYMAYbINxMDiMYwgQwjEcyplA0mTtsnVtp0rb1ao+xe4m7XZvsZt1SR/pn7Zd/dIn/cLu/gcOpXs+O7xnd/+eHdcHdr/8m08PHtjd//if7R08sHf4wM7+R/YO/sTl+R2X5ze+EH/l88d/8Mmj/58d118IekwknwthJL2Ylo9C1SD9tYymuclYEnbSjak/JmWIOEdPcNISmqaiGSpWxiaVTmClgxhJP4HAIwQlJpMqm9gVhUZLJzeqUL4doFkKx+o+k3qS7SBPr26z6VkM6zaJVJxs0abYnXMyXFOoljHtFB6/hODxuum24ny31Xn7rYNznqc06lLvNRgXPdRzX7Ecqtytyvz8osZidIqVTTBcDaiPxqx+uOO45KCaGrISQjjKWlhOjEq/yumkhRQXCWeixJ00WshFpyyxHOg8G9u8uCxxcz3AdqI0Rg2aixm1YRMtEUXWw/hDEkJE1VHiKiElRDQeJ2EqWLaGqkWJRWSScRkrESN7pJH4t3YCzdDREgZpJ4uZtlA1nbiu4ZckBG/QxC4syBSXnDS25MoLUrkpqcwZ3d45pVqbUn1CuzNlOZpQ622JGVlE0YsSjXJkp7GP81TqDaJaHCESMeh110ye3vLmx/e8fPWe2fyWymmP5XzAbLamPX3L4uonpvM7hlfvSBfO0E0Hp1QkX61SbDYotlqoSRPBThrcbtbMFmtunr9mc/OOV5cj6pVjxv0W88vXXKx/YbK8odl/RrO94KTTJ1t7ilO9oNJqk2+ckWt2UAwdIaqaNJojJsMq1998z+b6DaPekGza5LyWpVjtc9J7zvXmiu1kwPiiTuG0RnP1kkJ7SqU7pjVZUe70iGhRBFEUkWWZYFAiKIdRlAh+Scb9JIDk9+Lz+fH6ZWQ5REQOEomE8fl8yKEgEUVBDofxBwIEgkFEt8g/8DCCvncaOA4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7d79a383305f76787d327e678aef87f7/e4706/HTML5_Node-RED_Dashboard_04.avif 230w", "/en/static/7d79a383305f76787d327e678aef87f7/d1af7/HTML5_Node-RED_Dashboard_04.avif 460w", "/en/static/7d79a383305f76787d327e678aef87f7/c9875/HTML5_Node-RED_Dashboard_04.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7d79a383305f76787d327e678aef87f7/a0b58/HTML5_Node-RED_Dashboard_04.webp 230w", "/en/static/7d79a383305f76787d327e678aef87f7/bc10c/HTML5_Node-RED_Dashboard_04.webp 460w", "/en/static/7d79a383305f76787d327e678aef87f7/d00b9/HTML5_Node-RED_Dashboard_04.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7d79a383305f76787d327e678aef87f7/81c8e/HTML5_Node-RED_Dashboard_04.png 230w", "/en/static/7d79a383305f76787d327e678aef87f7/08a84/HTML5_Node-RED_Dashboard_04.png 460w", "/en/static/7d79a383305f76787d327e678aef87f7/5a190/HTML5_Node-RED_Dashboard_04.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7d79a383305f76787d327e678aef87f7/5a190/HTML5_Node-RED_Dashboard_04.png",
              "alt": "HTML5 Videostream für das Node-RED Dashboard",
              "title": "HTML5 Videostream für das Node-RED Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      